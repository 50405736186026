<template>
  <div class="home sb-nav-fixed">
    <top-header />
    <div id="layoutSidenav">
      <main-sidebar />
      <div id="layoutSidenav_content">
        <main id="main_content">
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from '../components/layouts/TopHeader'
import MainSidebar from '../components/layouts/MainSidebar'
import {mapGetters} from "vuex";

export default {
  name: 'Base',
  components: {
    'top-header': TopHeader,
    'main-sidebar': MainSidebar
  },
  computed:{
    ...mapGetters(["CIactivetrees"]),
  },
  watch: {
    '$route': function(to, from) {
      this.$store.commit('SET_ACTIVE_TREES', [])
    }
  },
}
</script>

<style scoped>

</style>
